//  const BaseUrl = "http://campusshala.com:15002" 
//  const BaseUrl = "http://localhost:15002" 
//const BaseUrl = "http://192.168.1.21:15002" 

//const  BaseUrl = "http://durgainfotech.hopto.org:15002"



const BaseUrl = "https://backend.krishnaconventschool.in"

//const BaseUrl = "http://krishnaconventschool.in/app"

export default BaseUrl;