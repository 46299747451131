import React, {useEffect, useState} from 'react';
import {useDropzone} from 'react-dropzone';
import { Button } from '@material-ui/core';
import { postData, postDataAndImage } from '../FetchServices';

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};


export default function Previews(props) {
  const [files, setFiles] = useState([]);
  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });
  
  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
        />
      </div>
    </div>
  ));

  const handleSubmit=async()=>{
      
    var formData=new FormData()
    for(var x = 0; x<files.length; x++) {
        formData.append('picture', files[x])
    }
   console.log(formData)

let config={headers:{'content-type':'multipart/form-data'}}  
console.log(formData)
let result=await postDataAndImage('postadd/addNewRecord',formData,config)
console.log(result)
if(result)
{alert("submit")
  }
else
{
alert("Fail to Submit Record...")
}

}


  useEffect(() => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  return (
    <section className="container">
      <div {...getRootProps({className: 'dropzone'})}>
        <input {...getInputProps()} />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
      <Button onClick={handleSubmit}>Submit</Button>
    </section>
  );
}