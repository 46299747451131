import React from 'react';
import {Route,BrowserRouter as Router} from 'react-router-dom'


import ListSelfRegister from '../component/agent/ListSelfRegister'
import UserProfile from '../component/agent/UserProfile'
import Activities from '../component/agent/dashboard/Activities'
import AddContact from '../component/agent/dashboard/AddContact'
import AddHomework from '../component/agent/dashboard/AddHomework'
import AddTask from '../component/agent/dashboard/AddTask'
import ClientPortfolio from '../component/agent/dashboard/ClientPortfolio'
import Dashboard from '../component/agent/dashboard/Dashboard'
import Diary from '../component/agent/dashboard/Diary'
import DisplayAddContact from '../component/agent/dashboard/DisplayAddContact'
import DisplayAddHomework from '../component/agent/dashboard/DisplayAddHomework'
import MainListItems from '../component/agent/dashboard/MainListItems'
import PostAdd from '../component/agent/dashboard/PostAdd'
import DisplayPostAdd from '../component/agent/dashboard/DisplayPostAdd'
import EditPostAdd from '../component/agent/dashboard/EditPostAdd'
import Explore from '../component/agent/dashboard/Explore/Explore'
import Map from '../component/agent/dashboard/Map' //it is use for Map (mapview)
import Userview from '../component/agent/dashboard/Userview' //it is use for Map (mapview)
import index from '../component/agent/dashboard/Map/index' //it is use for Map (mapview)
import GotoEnd from '../component/agent/dashboard/GotoEnd'
import ChatList from '../component/agent/dashboard/ChatList'
import EditProfile from '../component/agent/profile/EditProfile'
import AgentSignIn from '../component/agent/AgentSignIn'
import AgentEmailVerfied from '../component/agent/AgentEmailVerfied'
import MultipalImages from '../component/agent/MultipalImages'
import ClientViewList from '../component/agent/dashboard/ClientViewList';

import Cer from '../component/agent/dashboard/Certificate/Cer'


import Primary_Quaterly_Marksheet from '../component/agent/dashboard/Marksheet/Primary_Quaterly_Marksheet';
import Secondary_Marksheet from '../component/agent/dashboard/Marksheet/Secondary_Marksheet';
import SrSecondary_Marksheet from '../component/agent/dashboard/Marksheet/SrSecondary_Marksheet';
import Pcm_Marksheet from '../component/agent/dashboard/Marksheet/Pcm_Marksheet';
import Pcb_Marksheet from '../component/agent/dashboard/Marksheet/Pcb_Marksheet';
import Commerce_Marksheet from '../component/agent/dashboard/Marksheet/Commerce_Marksheet';

import Tc from './agent/dashboard/Tc/Tc.js';



import SecondaryTC from './agent/dashboard/Tc/SecondaryTC';

import PriTC  from './agent/dashboard/Tc/PriTC'
import SrSecondaryTC  from './agent/dashboard/Tc/SrSecondaryTC'
import PcmTC  from './agent/dashboard/Tc/PcmTC'
import PcbTC  from './agent/dashboard/Tc/PcbTC'
import CommerceTC  from './agent/dashboard/Tc/CommerceTC'


import ShowStudents from './agent/dashboard/showStudent/ShowStudents';

import Active from '../component/booking/Active';
import Cancel from '../component/booking/Cancel';
import Completed from '../component/booking/Completed';
import Refunded from '../component/booking/Refunded';
import BookingDetails from '../component/booking/BookingDetails';
import BookingRefund from '../component/booking/BookingRefund';

import PrivacyPolicy from '../component/agent/dashboard/PrivacyPolicy';


function ProjectRouter(props) {
  return (
   <Router>
     <div>


    
     <Route history={props.history} path='/' component={AgentSignIn} strict exact></Route>
     
      <Route history={props.history} path='/AgentSignIn' component={AgentSignIn} strict exact></Route>

     <Route history={props.history} path='/ListSelfRegister' component={ListSelfRegister} strict exact></Route>
     <Route history={props.history} path='/UserProfile' component={UserProfile} strict exact></Route> 
     <Route history={props.history} path='/Activities' component={Activities} strict exact></Route>
     <Route history={props.history} path='/AddContact' component={AddContact} strict exact></Route>
     <Route history={props.history} path='/AddHomework' component={AddHomework} strict exact></Route>
     <Route history={props.history} path='/AddTask' component={AddTask} strict exact></Route>
     <Route history={props.history} path='/ClientPortfolio' component={ClientPortfolio} strict exact></Route>
     <Route history={props.history} path='/Dashboard' component={Dashboard} strict exact></Route>
     <Route history={props.history} path='/Diary' component={Diary} strict exact></Route>
     <Route history={props.history} path='/DisplayAddContact' component={DisplayAddContact} strict exact></Route>
     <Route history={props.history} path='/DisplayAddHomework' component={DisplayAddHomework} strict exact></Route>
     <Route history={props.history} path='/MainListItems' component={MainListItems} strict exact></Route>  
     <Route history={props.history} path='/EditPostAdd ' component={EditPostAdd} strict exact></Route>
     <Route history={props.history} path='/PostAdd' component={PostAdd} strict exact></Route>
     <Route history={props.history} path='/DisplayPostAdd' component={DisplayPostAdd} strict exact></Route>
     <Route history={props.history} path='/Explore' component={Explore} strict exact></Route>
     <Route history={props.history} path='/Map' component={Map} strict exact></Route>
     <Route history={props.history} path='/Userview' component={Userview} strict exact></Route>
     <Route history={props.history} path='/index' component={index} strict exact></Route>
     <Route history={props.history} path='/GotoEnd' component={GotoEnd} strict exact></Route>
     <Route history={props.history} path='/ChatList' component={ChatList} strict exact></Route>
     <Route history={props.history} path='/EditProfile' component={EditProfile} strict exact></Route>
     <Route history={props.history} path='/AgentEmailVerfied' component={AgentEmailVerfied} strict exact></Route>
     <Route history={props.history} path='/MultipalImages' component={MultipalImages} strict exact></Route>
     <Route history={props.history} path='/ClientViewList' component={ClientViewList} strict exact></Route>


     <Route history={props.history} path='/Active' component={Active} strict exact></Route>
     <Route history={props.history} path='/Cancel' component={Cancel} strict exact></Route>
     <Route history={props.history} path='/Refunded' component={Refunded} strict exact></Route>
     <Route history={props.history} path='/Completed' component={Completed} strict exact></Route>
     <Route history={props.history} path='/BookingDetails' component={BookingDetails} strict exact></Route>
     <Route history={props.history} path='/PrivacyPolicy' component={PrivacyPolicy} strict exact></Route>
     <Route history={props.history} path='/BookingRefund' component={BookingRefund} strict exact></Route>

     <Route history={props.history} path='/PriMarksheet' component={Primary_Quaterly_Marksheet} strict exact></Route>
     <Route history={props.history} path='/SecondaryMarksheet' component={Secondary_Marksheet} strict exact></Route>
     <Route history={props.history} path='/SrSecondaryMarksheet' component={SrSecondary_Marksheet} strict exact></Route>
     <Route history={props.history} path='/PcmMarksheet' component={Pcm_Marksheet} strict exact></Route>
     <Route history={props.history} path='/PcbMarksheet' component={Pcb_Marksheet} strict exact></Route>
     <Route history={props.history} path='/CommerceMarksheet' component={Commerce_Marksheet} strict exact></Route>

     <Route history={props.history} path='/Tc' component={Tc} strict exact></Route>

     <Route history={props.history} path='/PriTC' component={PriTC} strict exact></Route>
     <Route history={props.history} path='/SecondaryTC' component={SecondaryTC} strict exact></Route>
     <Route history={props.history} path='/SrSecondaryTC' component={SrSecondaryTC} strict exact></Route>
     <Route history={props.history} path='/PcmTC' component={PcmTC} strict exact></Route>
     <Route history={props.history} path='/PcbTC' component={PcbTC} strict exact></Route>
     <Route history={props.history} path='/CommerceTC' component={CommerceTC} strict exact></Route>
     

     <Route history={props.history} path='/Cer' component={Cer} strict exact></Route>
     <Route history={props.history} path='/ShowStudents' component={ShowStudents} strict exact></Route>

     
    </div>
    </Router>   
  );
}

export default ProjectRouter;