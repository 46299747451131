import React from 'react';
import './App.css';

import SearchIcon from '@material-ui/icons/Search';
import SearchBar from 'material-ui-search-bar'


import ProjectRouter from './component/ProjectRouter';


function App(props) {
  return (
    <div>
      {/* <Main/> */}
 <ProjectRouter/>     
 
    </div>
  );
}

export default App;
